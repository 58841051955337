.markdown > * {
  all: revert;
}

/* Link Styles */
.markdown a {
  color: #920025;
  transition: color 0.3s ease;
}

.markdown a:hover {
  color: #603b61;
}

/* Table Styles - Apply to both markdown and regular tables */
.markdown table,
.table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
  font-size: 1em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.markdown table thead tr,
.table thead tr {
  background-color: #374151;
  color: #ffffff;
  text-align: left;
}

.markdown table th,
.markdown table td,
.table th,
.table td {
  padding: 8px 6px;
  border: 1px solid #2d3748;
  color: #ffffff;
}

.markdown table tbody tr:nth-child(even),
.table tbody tr:nth-child(even) {
  background-color: #1f2937;
}

.markdown table tbody tr:nth-child(odd),
.table tbody tr:nth-child(odd) {
  background-color: #111827;
}

.markdown table tbody tr:hover,
.table tbody tr:hover {
  background-color: #374151;
  color: #ffffff;
}

/* Container Styles */
.markdown-container,
.markdown-container-wide {
  flex: 1;
  overflow-y: auto;
  padding: 2rem;
  margin-top: 5vh;
  max-height: calc(95vh - 4rem);
}

.markdown-container {
  width: 56%;
  margin-left: auto;
  margin-right: auto;
}

.markdown-container-wide {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .markdown-container {
    width: 75%;
  }
}

.disclaimer {
  color: red;
  font-style: italic;
}

/* Hide Scrollbars */
html,
body {
  overflow: hidden;
}

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.markdown-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.markdown-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Apply these styles to the body and html elements to hide scrollbars globally */
body,
html {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body::-webkit-scrollbar,
html::-webkit-scrollbar {
  display: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

pre code {
  background-color: #2d2d2d;
  color: #ccc;
  padding: 16px;
  border-radius: 4px;
  display: block;
  overflow-x: auto;
  font-family: 'Courier New', Courier, monospace;
}

 .keyword {
  color: #cc99cd;
  font-weight: bold;
}

 .string {
  color: #7ec699;
}

 .comment {
  color: #999999;
  font-style: italic;
}
